import styled from "styled-components"
import { Col } from "../main"

export const Item = styled(Col)`
  width: 100%;
  padding: 10px 0;
  color: #222;
  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`

export const NewsTitle = styled.span`
  font-size: 1.4rem;
  font-weight: 600;
`

export const Deta = styled.span`
  color: #9096a2;
  font-weight: 600;
  @media screen and (min-width: 1024px) {
    margin-right: 40px;
  }
`
