import React from "react"
import Lottie from "react-lottie"
import animationData from "../liquid.json"
import { Wrapper, Img, Bg } from "./style"
import Fade from "react-reveal/Fade"

const Home = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
  return (
    <>
      <Wrapper>
        <div>
          <Fade bottom delay={800}>
            <h1>
              Create
              <br />
              Your Place .
            </h1>
          </Fade>
          <Fade bottom delay={1600}>
            <h3>居場所をつくる</h3>
          </Fade>
        </div>
        <Img src="https://assets-global.website-files.com/5bfd1275cc56e15ce750b18e/5d052f246a936053d6936212_App%20Development.png" />
      </Wrapper>
      <Bg>
        <Lottie options={defaultOptions} />
      </Bg>
    </>
  )
}
export default Home
