import styled from "styled-components"
import { Col } from "../main"

export const Items = styled(Col)`
  width: 100%;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const Item = styled(Col)`
  @media screen and (min-width: 768px) {
    width: 46%;
    max-width: 400px;
  }
`

export const Img = styled.img`
  width: 100%;
  height: auto;
  border-radius: 20px;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
  margin: 10px 0 15px -40px;
  transition: all 0.2s;
  :hover {
    filter: drop-shadow(0px 2px 15px rgba(0, 0, 0, 0.25));
  }
  @media screen and (min-width: 768px) {
    margin: 10px 0 15px 0px;
  }
`

export const ItemTitle = styled.span`
  font-size: 2.4rem;
  font-weight: 600;
`

export const Detail = styled.span`
  font-size: 1.4rem;
  margin: 5px 0 40px;
  @media screen and (min-width: 768px) {
    font-size: 1.2rem;
  }
`
