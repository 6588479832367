import React, { useState, useEffect } from "react"
import { Wrapper, SubTitle } from "../main"
import { Items, Item, Img, ItemTitle, Detail } from "./style"
import { PlayIcon, PauseIcon } from "../../images"

const Works = () => (
  <Wrapper>
    <SubTitle>Works</SubTitle>
    <WorksItems />
  </Wrapper>
)
export default Works

const links = ["https://request.aw-with.com/", "https://rkbr.jp/"]

const srcs = [
  "https://storage.googleapis.com/writone-ja-admin/retorab.png",
  "https://storage.googleapis.com/writone-ja-admin/rkb.png",
]
const audios = [
  "https://storage.googleapis.com/writone-ja-audios/wrS020zr4joaLCjvv4p3_output.m4a",
  "https://storage.googleapis.com/lyact-corporate.appspot.com/suita.mp3",
]
const titles = ["Retorab | リトラビ", "RKBラジオ"]

const texts = [
  "アイシングループ、イオンモール常滑、愛知道路コンセッションと共同し、“移動”を“冒険”に変える次世代広告システム『リトラビ』の実証実験を行っています。",
  "RKBラジオとのコラボ企画として、弊社サービス『Writone（ライトーン）』で制作されたオーディオブックから厳選した７作品を『宮岡プラチナゲート〜RKBからの挑戦状！〜』にて、４日間にわたり放送しました。",
]

const WorksItems = () => {
  const { playing, index, play, pause } = usePlayer()

  return (
    <Items>
      {titles.map((title, i) => {
        return (
          <Item key={i}>
            <div style={{ position: "relative" }}>
              <a
                href={links[i]}
                target="_blank"
                rel="noopener noreferrer"
                key={i}
              >
                <Img src={srcs[i]} alt="worksImage" />
              </a>
              {playing && index === i ? (
                <PauseIcon onClick={() => pause()} />
              ) : (
                <PlayIcon onClick={() => play(i)} />
              )}
            </div>
            <ItemTitle>{title}</ItemTitle>
            <Detail>{texts[i]}</Detail>
          </Item>
        )
      })}
    </Items>
  )
}

const usePlayer = () => {
  const [playing, toggle] = useState(false)
  const [index, setIndex] = useState(90)
  const [el, setEl] = useState()

  useEffect(() => {
    const el = document.getElementById("audio")
    setEl(el)
    const onEnd = () => {
      toggle(false)
    }
    el.addEventListener("ended", onEnd)
    return () => {
      el.removeEventListener("ended", onEnd)
    }
  }, [el])

  const play = i => {
    if (!el) return
    if (i !== index) {
      setIndex(i)
      el.src = audios[i]
      el.play()
      toggle(true)
    } else {
      el.play()
      toggle(true)
    }
  }
  const pause = () => {
    toggle(false)
    el.pause()
  }
  return {
    playing: playing,
    index: index,
    play: play,
    pause: pause,
  }
}
