import styled from "styled-components"
import { Wrapper } from "../main"

export const ServiceWrapper = styled(Wrapper)`
  @media screen and (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
  }
`

export const Img = styled.img`
  width: 240px;
  margin: 60px auto 0;
  @media screen and (min-width: 1024px) {
    margin: 60px 15% 0 0;
  }
  @media screen and (min-width: 1280px) {
    width: 300px;
  }
`
