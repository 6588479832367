import styled from "styled-components"
import { Col } from "../main"

export const Wrapper = styled(Col)`
  position: relative;
  width: calc(100% - 40px);
  height: calc(100vh - 200px);
  justify-content: flex-end;
  padding: 100px 20px;
  overflow-x: hidden;
  @media screen and (min-width: 768px) {
    width: calc(100% - 80px);
    padding: 100px 40px;
  }
  @media screen and (min-width: 1280px) {
    width: calc(100% - 160px);
    padding: 100px 80px;
  }
`

export const Img = styled.img`
  position: absolute;
  width: 65%;
  right: -5px;
  bottom: 250px;
  @media screen and (min-width: 768px) {
    width: 400px;
    right: 30px;
    top: 90px;
  }
`

export const Bg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -10;
  opacity: 0.8;
`
