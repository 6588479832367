import React from "react"
import Fade from "react-reveal/Fade"
import { CenterWrapper, SubTitle, TitleSpan, Text } from "../main"

const About = () => (
  <CenterWrapper>
    <Fade bottom delay={400}>
      <SubTitle>Vision</SubTitle>
    </Fade>
    <Fade bottom delay={600}>
      <TitleSpan>多くの人が夢を諦める</TitleSpan>
      <TitleSpan>この世界を変える</TitleSpan>
    </Fade>
    <Fade bottom delay={800}>
      <SubTitle>Mission</SubTitle>
    </Fade>
    <Fade bottom delay={1000}>
      <TitleSpan>居場所をつくる</TitleSpan>
    </Fade>
    <Fade bottom delay={1200}>
      <Text>
        夢に向かって努力をしていても、なかなか活躍できる場所がなく、
        希望がもてずに夢を諦めてしまう人が多いこの世界を変えたい。
        私たちは夢を叶えたい人たちの"居場所をつくる"ことをミッションにしています。
      </Text>
    </Fade>
  </CenterWrapper>
)
export default About
