import React from "react"
import { CenterWrapper, SubTitle } from "../main"
import { Item, NewsTitle, Deta } from "./style"
import { Col } from "../main"

const News = () => (
  <CenterWrapper>
    <SubTitle>News</SubTitle>
    <NewsItems />
  </CenterWrapper>
)
export default News

const titles = [
  "弊社代表がTVQ九州放送『ぐっ！ジョブ』に出演しました！",
  "弊社代表がNewsPicksの『メイクマネー』に出演しました！",
  "RKBラジオ×Writoneのコラボ企画を行いました！",
  "バンダイナムコアクセラレーター2018（第１期）に採択されました！",
]
const texts = ["2019.04.13", "2019.04.07", "2018.11.01", "2018.10.03"]
const links = [
  "https://www.tvq.co.jp/o_j/goodjob/archive/detail.html?id=20190413",
  "https://newspicks.com/live-movie/292/",
  "https://prtimes.jp/main/html/rd/p/000000005.000035062.html",
  "http://bandainamco.01booster.com/",
]

const NewsItems = () => {
  return (
    <Col>
      {titles.map((title, i) => {
        return (
          <a href={links[i]} target="_blank" rel="noopener noreferrer" key={i}>
            <Item>
              <Deta>{texts[i]}</Deta>
              <NewsTitle>{title}</NewsTitle>
            </Item>
          </a>
        )
      })}
    </Col>
  )
}
