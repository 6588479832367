import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Home from "../components/home"
import About from "../components/about"
import Service from "../components/service"
import Works from "../components/works"
import News from "../components/news"

const Index = () => (
  <Layout>
    <SEO title="Create Your Place - 居場所をつくる" />
    <audio id="audio" />
    <Home />
    <About />
    <Service />
    <Works />
    <News />
  </Layout>
)

export default Index
