import React from "react"
import { Link } from "gatsby"
import Button from "../button"
import { ServiceWrapper, Img } from "./style"
import { Col, SubTitle, TitleSpan, Text } from "../main"

const Service = () => (
  <ServiceWrapper>
    <Img src="https://assets-global.website-files.com/5bfd1275cc56e15ce750b18e/5d052f246a936062cd9361f1_Launch.png" />
    <Col>
      <SubTitle>Service</SubTitle>
      <TitleSpan>声優とライターでつくる</TitleSpan>
      <TitleSpan>音声コンテンツで、</TitleSpan>
      <TitleSpan>新たな出会いを。</TitleSpan>
      <Text>
        私たちは、新規事業立ち上げやブランディングなど、クライアント企業の様々な課題を音声コンテンツのチカラで解決します。
      </Text>
      <Link to="/service" style={{ margin: "auto" }}>
        <Button>View more</Button>
      </Link>
    </Col>
  </ServiceWrapper>
)
export default Service
